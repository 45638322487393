import React from "react";
import SEO from "../components/SEO";
import SolidBGHeroSection from "@src/components/SolidBGHeroSection";
import LearnMore from "../components/partials/index/LearnMoreSection";
import Features from "../components/partials/schoolleader/FeaturesSection";
import TeacherLp3FeaturesSection from "../components/partials/teacherlp3/TeacherLp3FeaturesSection";
import { theme } from "@src/components/nessie-web";

const {
  colors: { dt_aqua50 },
} = theme;

const IndexPage = () => {
  const SolidBGHeroSectionProps = {
    title: "directus.page_teacherlp3.hero_title",
    subtitle: "directus.page_teacherlp3.hero_subtitle",
    sentence: "directus.page_teacherlp3.hero_text",
    BGColor: dt_aqua50,
  };

  return (
    <>
      <SEO
        title="Remote Learning on ClassDojo"
        description="ClassDojo brings your school together, wherever you are, free"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Remote Learning on ClassDojo",
          description: "ClassDojo brings your school together, wherever you are, free",
        }}
      />
      <SolidBGHeroSection {...SolidBGHeroSectionProps} />
      <TeacherLp3FeaturesSection />
      <LearnMore hideCta />
      <Features />
    </>
  );
};
export default IndexPage;
